import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Stack,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { Navigate, useParams, Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSnackbar } from "notistack";

import Header from "../../components/Header";
import { SNACKBAT_AUTO_HIDE_DURATION } from "../../utilities/constants";
import firmApi from "../../apis/firmApi";
import codeTablePostgresApi from "../../apis/codeTablePostgresApi";

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const noOfEmployeeOptions = ["1-10", "11-100", "101-500", "501-1000", "1001+"];
  const { enqueueSnackbar } = useSnackbar();
  const [codeTables, setCodeTables] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const { firm_id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);

  const loggedInUser = useSelector((state) => {
    return state.user;
  });

  const tenantId = useSelector((state) => {
    return state.tenantId;
  }); 

  const idToken = useSelector((state) => {
    return state.token;
  });

  const [selectedFirm, setSelectedFirm] = useState({
    firm_name: "",
    tenant_id: tenantId,
    firm_type_id: "",
    phone: "",
    fax: "",
    physical_address: "",
    mailing_address: "",
    no_of_employee: "",
    wholesale: "",
    interstate: "",
    website: "",
    registration: "",
  });

  const checkoutSchema = yup.object().shape({
    firm_name: yup.string().required("required"),
    tenant_id: yup.string(),
    firm_type_id: yup.number().required("required"),
    phone: yup.string(),
    fax: yup.string(),
    physical_address: yup.string(),
    mailing_address: yup.string(),
    no_of_employee: yup.string(),
    wholesale: yup.number(),
    interstate: yup.number(),
    website: yup.string(),
    registration: yup.string(),
  });

  const handleFormSubmit = async (values) => {
    setIsLoading(true);


    const saveFirm = {
      subject: values.subject,
      firm_name: values.firm_name,
      firm_type_id: values.firm_type_id,
      tenant_id: tenantId,
      phone: values.phone,
      fax: values.fax,
      physical_address: values.physical_address,
      mailing_address: values.mailing_address,
      no_of_employee: values.no_of_employee,
      wholesale: values.wholesale === '' ? null : values.wholesale,
      interstate: values.interstate === '' ? null : values.interstate,
      website: values.website,
      registration: values.registration,
    };

    try {
      const requestHeader = {
        headers: {
          "x-eqip-tenantid": tenantId,
          "Authorization": idToken
        },
      };

      if (firm_id) {
        const editedFirm = {
          ...saveFirm,
          firm_uid: selectedFirm.firm_uid,
          updated_by: loggedInUser.email,
        };
        await firmApi.put(`/firms/${editedFirm.firm_uid}`, editedFirm, requestHeader);
      } else {
        saveFirm.created_by = loggedInUser.email;
        await firmApi.post("/firms/", saveFirm, requestHeader);
      }

      enqueueSnackbar("The firm save successfully!", {
        variant: "success",
        autoHideDuration: SNACKBAT_AUTO_HIDE_DURATION,
      });
      setIsRedirect(true);
      setIsLoading(false);
    } catch (err) {
      // Handle Error Here
      setErrorMessage(`Failed to create/update a Firm. Error: ${err}`)
      setIsLoading(false);
      console.error(err);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const [responseCodeTable] =
        await Promise.all([
          codeTablePostgresApi.get(`/codetables/codetabletypes/14`)
        ]);
      setCodeTables(responseCodeTable.data.body);
      if (firm_id) {
        const [responseFirm] =
          await Promise.all([
            firmApi.get(`/firms/${firm_id}`)
          ]);

        setSelectedFirm(responseFirm.data.body[0]);
        setIsLoading(false);
      }
    } catch (err) {
      // Handle Error Here
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box m="20px">
      {isRedirect && <Navigate to="/firms" />}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Header title="FIRM" subtitle="Create/Update a Firm" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={selectedFirm}
        validationSchema={checkoutSchema}
        enableReinitialize={true} // Important: allow to reload data on useEffect
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              {errorMessage.length > 0 && 
              <Alert onClose={() => setErrorMessage('')} 
                severity="error"
                sx={{ gridColumn: "span 4" }}
              >
                {errorMessage}
              </Alert>
              }
              <TextField
                fullWidth
                type="text"
                label="Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firm_name}
                name="firm_name"
                error={!!touched.firm_name && !!errors.firm_name}
                helperText={touched.firm_name && errors.firm_name}
                sx={{ gridColumn: "span 2" }}
              />
              <FormControl sx={{ gridColumn: "span 2" }}>
                <InputLabel id="firm-label">Firm Type</InputLabel>
                <Select
                  fullWidth
                  label="Firm Type"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firm_type_id}
                  name="firm_type_id"
                  error={!!touched.firm_type_id && !!errors.firm_type_id}
                  helperText={touched.firm_type_id && errors.firm_type_id}
                >
                  {codeTables.map(option => 
                    <MenuItem key={option.id} value={option.id}>{option.description}</MenuItem>)}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                type="text"
                label="Business Phone"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone}
                name="phone"
                sx={{ gridColumn: "span 2" }}
              />
              <FormControl sx={{ gridColumn: "span 2" }}>
                <InputLabel id="firm-label">No. of Employee</InputLabel>
                <Select
                  fullWidth
                  label="No. of Employee"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.no_of_employee}
                  name="no_of_employee"
                >
                  {noOfEmployeeOptions.map(option => 
                    <MenuItem key={option} value={option}>{option}</MenuItem>)}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                type="text"
                label="Fax"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fax}
                name="fax"
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                type="text"
                label="Wholesale"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.wholesale}
                name="wholesale"
                sx={{ gridColumn: "span 2" }}
                error={!!touched.wholesale && !!errors.wholesale}
                helperText={touched.wholesale && errors.wholesale }
              />
              <TextField
                fullWidth
                type="text"
                label="Physical Address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.physical_address}
                name="physical_address"
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                type="text"
                label="Interstate"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.interstate}
                name="interstate"
                sx={{ gridColumn: "span 2" }}
                error={!!touched.interstate && !!errors.interstate}
                helperText={touched.interstate && errors.interstate }
              />
              <TextField
                fullWidth
                type="text"
                label="Mailing Address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.mailing_address}
                name="mailing_address"
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                type="text"
                label="Website"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.website}
                name="website"
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                type="text"
                label="Registration"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.registration}
                name="registration"
                sx={{ gridColumn: "span 2" }}
              />

            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Stack direction="row" spacing={1}>
                <Button
                  type="submit"
                  color="info"
                  variant="outlined"
                  component={Link}
                  to="/firms"
                  startIcon={<CancelIcon />}
                  onClick={() => setSubmitting(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isLoading}
                  startIcon={<SaveIcon />}
                >
                  Save
                </Button>
              </Stack>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Form;
