import { createSlice } from "@reduxjs/toolkit";

// This is a global state
const initialState = {
  mode: "light",
  user: null,
  token: null, //it is IdToken from aws cognito, it used to check whether user has access to api endpoints (api authorization)
  accessToken: null, // it is used to check token expiry date and time
  isShowInspection: false,
  routeList: [],
  tenantId: null,
  globalCommodity: null,
  globalMarket: null,
  
};

export const authSlice = createSlice({
  name: "auth", // auth workflow
  initialState,
  reducers: {
    // reducer is a function that modify global state
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setLogin: (state, action) => {
      state.email = action.payload.user.email;
      state.user = action.payload.user;
      state.refreshToken = action.payload.refreshToken;
      state.token = action.payload.token;
      state.accessToken = action.payload.accessToken;
    },
    setRole: (state, action) => {
      state.role = action.payload.role;
    },
    setLogout: (state) => {
      state.user = null;
      state.refreshToken = null;
      state.token = null;
      state.accessToken = null;
      state.isShowInspection = false;
      state.routeList = null;
    },
    setRouteList: (state, action) => {
      state.routeList = action.payload;
    },
    setInspection: (state, action) => {
      state.isShowInspection = action.payload.isShowInspection;
      state.inspectionUid = action.payload.inspectionUid;
      state.assignmentUid = action.payload.assignmentUid;
    },
    setTenantId: (state, action) => {
      state.tenantId = action.payload.tenantId;
    },
    setGlobalCommodity: (state, action) => {
      state.globalCommodity = action.payload.globalCommodity;
    },
    setGlobalMarket: (state, action) => {
      state.globalMarket = action.payload.globalMarket;
    },
    setAccessToken: (state, action) => {
      state.token = action.payload.token;
      state.accessToken = action.payload.accessToken;
    },
  },
});

export const { setMode, setLogin, setRole, setLogout, setInspection, setRouteList, setTenantId, setGlobalCommodity, setGlobalMarket, setAccessToken} = authSlice.actions; // Redux toolkit pattern
export default authSlice.reducer;
