
import { Grid, Typography, CircularProgress } from "@mui/material";
import styled from "@emotion/styled";
import { COVERAGE_CHOICE_CITATION_GUID } from "../../../utilities/constants";
import { transformCitationLanguage } from "../../../utilities/helpers";

const CitationReport = ({inspection}) => {
    const GridWrapper = styled(Grid) ({
        border: "1px solid grey"
    });
    
    const PaperHeader = styled(Typography) ({
        fontSize: '1.5em',
    });

    const protocolQualities = inspection?.protocol_quality;
    const filteredQuestionCitations = [];
    protocolQualities?.forEach(protocolQuality => {
        if (protocolQuality.detail?.coverageChoice === COVERAGE_CHOICE_CITATION_GUID) {
            filteredQuestionCitations.push(protocolQuality);
        }
    });
    
    return ( filteredQuestionCitations ?
        <GridWrapper container item xs={12}>
            {filteredQuestionCitations.map((filteredQuestionCitation) => (
                <Grid key={filteredQuestionCitation?.code} container item xs={12}>
                    <GridWrapper item xs={12}>
                        <PaperHeader>
                            {transformCitationLanguage( filteredQuestionCitation?.detail?.citationLanguage)}
                        </PaperHeader>
                    </GridWrapper>
                    <CitationDetailReport commentTitle="Specifically" commentValue={filteredQuestionCitation?.detail?.citationSpecifically} />
                    <CitationDetailReport commentTitle="Supporting Evidence and Relevance" commentValue={filteredQuestionCitation?.detail?.citationSupporting} />
                    <CitationDetailReport commentTitle="Recommendation" commentValue={filteredQuestionCitation?.detail?.citationDiscussion} />
                </Grid>
            ))}
        </GridWrapper> : <><CircularProgress />Citation Loading... </>
    );
};

const CitationDetailReport = ({commentTitle, commentValue}) => {
    const GridWrapper = styled(Grid) ({
        border: "1px solid grey"
    });

    const Paper = styled(Grid) ({
    });

    return <>{commentValue && 
        <GridWrapper item xs={12} style={{paddingLeft:'2em'}}>
            <Paper>{commentTitle}</Paper>
            <Paper>{commentValue}</Paper>
        </GridWrapper>
    }
    </>
}

export default CitationReport;
