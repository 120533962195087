import React from "react";
import { Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
import { getLocalDate, transformCitationLanguage } from "../../utilities/helpers";
import { COVERAGE_CHOICE_CITATION_GUID, COVERAGE_CHOICE_COVERED_DISCUSS_ONLY_GUID, REPORT_TYPE } from "../../utilities/constants";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    fontSize: "10px",
    paddingLeft:"5px",
    paddingRight:"5px"
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    width: "100%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #a6a6a6",
    paddingTop: 8,
    paddingBottom: 8,
  },
  rowNoBorder: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 4,
    paddingBottom: 4,
  },
  rowSystemChoice: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #ebebeb",
    paddingTop: 4,
    paddingBottom: 4,
  },
  header: {
    borderTop: "none",
  },
  title: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  subTitle: {
    fontSize: "14px",
  },
  bold: {
    fontWeight: "bold",
    fontSize: "12px",
  },
  colTitle100: {
    textAlign: "center",
    width: "100%",
  },
  col100: {
    textAlign: "left",
    width: "100%",
  },
  col70: {
    textAlign: "left",
    width: "70%",
  },
  col50: {
    textAlign: "left",
    width: "50%",
  },
  col30: {
    textAlign: "left",
    width: "30%",
  },
  signature: {
    width: "200px",
    height: "40px"
  },
});

// Create Document Component
const ObservationReportPdf = ({ reportType, assignment, codeTables, questionCitations, allowedAssignTos }) => {
  let globalObservationIndex = 0;
  let globalDiscussionIndex = 0;
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.table}>
          <View style={styles.row} wrap={false}>
            <Text style={styles.colTitle100}>
              <Text style={styles.title}>MOCK AUDIT</Text>
            </Text>
          </View>
          <View style={styles.row} wrap={false}>
            <Text style={styles.colTitle100}>
              <Text style={styles.subTitle}>OBSERVATIONS</Text>
            </Text>
          </View>
          <View style={styles.row} wrap={false}>
            <Text style={styles.col70}>
              <Text>AUDITING FIRM ADDRESS AND PHONE NUMBER</Text>
            </Text>
            <Text style={styles.col30}>
              <Text>DATE(S) OF INSPECTION</Text>
            </Text>
          </View>
          <View style={styles.rowNoBorder} wrap={false}>
            <Text style={styles.col70}>
              <Text>Name: {assignment.auditing_firm_name}</Text>
            </Text>
            <Text style={styles.col30}>
              <Text>
                {getLocalDate(assignment.assignment_date)} - {getLocalDate(assignment.target_complete_date)}
              </Text>
            </Text>
          </View>
          <View style={styles.rowNoBorder} wrap={false}>
            <Text style={styles.col70}>
              <Text>Address: {assignment.auditing_firm_address}</Text>
            </Text>
            <Text style={styles.col70}>
              <Text> </Text>
            </Text>
          </View>
          <View style={styles.rowNoBorder} wrap={false}>
            <Text style={styles.col70}>
              <Text>Phone: {assignment.auditing_firm_phone}</Text>
            </Text>
            <Text style={styles.col70}>
              <Text> </Text>
            </Text>
          </View>
          <View style={styles.rowNoBorder} wrap={false}>
            <Text style={styles.col70}>
              <Text>Fax: {assignment.auditing_firm_fax}</Text>
            </Text>
            <Text style={styles.col70}>
              <Text> </Text>
            </Text>
          </View>
          <View style={styles.rowNoBorder} wrap={false}>
            <Text style={styles.col70}>
              <Text>Industry Information: www.fda.gov/oc/industry:</Text>
            </Text>
            <Text style={styles.col70}>
              <Text> </Text>
            </Text>
          </View>
          <View style={styles.row} wrap={false}>
            <Text style={styles.col100}>
              <Text style={styles.bold}>NAME AND TITLE OF INDIVIDUAL TO WHOM REPORT IS ISSUED</Text>
            </Text>
          </View>
          <View style={styles.rowNoBorder} wrap={false}>
            <Text style={styles.col100}>
              <Text>To: Phillip R. Bradway, Senior Vice President of Engineering, Science, & Technology</Text>
            </Text>
          </View>
          <View style={styles.row} wrap={false}>
            <Text style={styles.col50}>
              <Text style={styles.bold}>FIRM TO BE AUDITED</Text>
            </Text>
            <Text style={styles.col50}>
              <Text style={styles.bold}>TYPE ESTABLISHMENT INSPECTED</Text>
            </Text>
          </View>
          <View style={styles.rowNoBorder} wrap={false}>
            <Text style={styles.col50}>
              <Text>Name: {assignment.audited_firm_name}</Text>
            </Text>
            <Text style={styles.col50}>
              <Text>Food Manufacturer</Text>
            </Text>
          </View>
          <View style={styles.rowNoBorder} wrap={false}>
            <Text style={styles.col50}>
              <Text>Address: {assignment.audited_firm_address}</Text>
            </Text>
            <Text style={styles.col50}>
              <Text></Text>
            </Text>
          </View>
          <View style={styles.rowNoBorder} wrap={false}>
            <Text style={styles.col50}>
              <Text>Phone: {assignment.audited_firm_phone}</Text>
            </Text>
            <Text style={styles.col50}>
              <Text></Text>
            </Text>
          </View>
          <View style={styles.rowNoBorder} wrap={false}>
            <Text style={styles.col50}>
              <Text>Fax: {assignment.audited_firm_fax}</Text>
            </Text>
            <Text style={styles.col50}>
              <Text></Text>
            </Text>
          </View>
          <View style={styles.row} wrap={false}>
            <Text style={styles.col100}>
              <Text style={styles.bold}>INSPECTION DETAIL</Text>
            </Text>
          </View>
          <View style={styles.rowNoBorder} wrap={false}>
            <Text style={styles.col100}>
              <Text>TO BE FILLED</Text>
            </Text>
          </View>
          <View style={styles.row} wrap={false}>
            <Text style={styles.col100}>
              {reportType === REPORT_TYPE.OBSERVATION && <Text style={styles.bold}>DURING AN AUDIT OF YOUR FIRM I/WE OBSERVED:</Text>}
              {reportType === REPORT_TYPE.DISCUSSION && <Text style={styles.bold}>DURING AN AUDIT OF YOUR FIRM I/WE DISCUSSED:</Text>}
            </Text>
          </View>
           {reportType === REPORT_TYPE.OBSERVATION &&
            codeTables &&
            codeTables.map((codeTable) => {
              const questionCitationsPerSystemChoice = questionCitations?.filter((p) => p.detail?.systemChoice === codeTable.code_table_uid);
              return (
                questionCitationsPerSystemChoice?.length > 0 &&
                questionCitationsPerSystemChoice.some((questionCitation) => questionCitation.detail.coverageChoice === COVERAGE_CHOICE_CITATION_GUID) && (
                  <View key={questionCitationsPerSystemChoice[0].code}>
                    <View style={styles.rowSystemChoice} wrap={false}>
                      <Text style={styles.col100}>
                        <Text>{codeTable.description.toUpperCase()}</Text>
                      </Text>
                    </View>

                    {questionCitationsPerSystemChoice
                      .filter((choice) => choice.detail.coverageChoice === COVERAGE_CHOICE_CITATION_GUID)
                      .map((questionCitation) => {
                        const { citationLanguage, citationSpecifically } = questionCitation.detail;
                        if (questionCitation) {
                          globalObservationIndex += 1;
                          return (
                            <View>
                              <View style={styles.rowNoBorder} wrap={false}>
                                <Text style={styles.col100}>OBSERVATION {globalObservationIndex}</Text>
                              </View>
                              <View style={styles.rowNoBorder} wrap={false}>
                                <Text style={styles.col100}>
                                  <Text>{transformCitationLanguage(citationLanguage)}</Text>
                                </Text>
                              </View>
                              <View style={styles.rowNoBorder} wrap={false}>
                                <Text style={styles.col100}>
                                  <Text>Specifically, {citationSpecifically}</Text>
                                </Text>
                              </View>
                            </View>
                          );
                        }
                        return <View/>;
                      })}
                  </View>
                )
              );
            })}
          {reportType === REPORT_TYPE.DISCUSSION &&
            codeTables &&
            codeTables.map((codeTable) => {
              const questionCitationsPerSystemChoice = questionCitations?.filter((p) => p.detail?.systemChoice === codeTable.code_table_uid);
              return (
                questionCitationsPerSystemChoice?.length > 0 &&
                questionCitationsPerSystemChoice.some((questionCitation) => questionCitation.detail.coverageChoice === COVERAGE_CHOICE_COVERED_DISCUSS_ONLY_GUID) && (
                  <View key={questionCitationsPerSystemChoice[0].code}>
                    <View style={styles.rowSystemChoice} wrap={false}>
                      <Text style={styles.col100}>
                        <Text style={styles.systemChoice}>{codeTable.description}</Text>
                      </Text>
                    </View>

                    {questionCitationsPerSystemChoice
                      .filter((choice) => choice.detail.coverageChoice === COVERAGE_CHOICE_COVERED_DISCUSS_ONLY_GUID)
                      .map((questionCitation) => {
                        const { citationLanguage, citationSpecifically } = questionCitation.detail;
                        if (questionCitation) {
                          globalDiscussionIndex += 1;
                          return (
                            <View>
                              <View style={styles.rowNoBorder} wrap={false}>
                                <Text style={styles.col100}>
                                  <Text>DISCUSSION {globalDiscussionIndex}</Text>
                                </Text>
                              </View>
                              <View style={styles.rowNoBorder} wrap={false}>
                                <Text style={styles.col100}>
                                  <Text>{transformCitationLanguage(citationLanguage)}</Text>
                                </Text>
                              </View>
                              <View style={styles.rowNoBorder} wrap={false}>
                                <Text style={styles.col100}>
                                  <Text>Specifically, {citationSpecifically}</Text>
                                </Text>
                              </View>
                            </View>
                          );
                        }
                        return <View/>;
                      })}
                  </View>
                )
              );
            })}
          <View style={styles.row} wrap={false}>
            <Text style={styles.col100}>
              <Text>EMPLOYEE(S) SIGNATURE</Text>
            </Text>
          </View>
          {allowedAssignTos?.map((item) => { 
            return <View key={item.user_name}>
                <View style={styles.rowNoBorder} wrap={false}>
                    <Text style={styles.col100}>
                    <Text>{item.user_name} - {item.roleName}</Text>
                    </Text>
                </View>
                <View style={styles.rowNoBorder} wrap={false}>
                    <Text style={styles.col100}>
                        {item.signature_url && <Image style={styles.signature} src={item.signature_url} alt="Not Found"  />}
                    </Text>
                </View>
            </View>
          })}
          
        </View>
      </Page>
    </Document>
  );
};

export default ObservationReportPdf;
