import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import inspectionApi from "../../../apis/inspectionApi";
import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { DragDropContext } from "react-beautiful-dnd";
import SystemChoiceDroppable from "./SystemChoiceDroppable";
import { REPORT_TYPE } from "../../../utilities/constants";

const CoverageDnD = ({ coverageDndType, coverageChoiceGuid, systemChoiceField }) => {
  const [inspection, setInspection] = useState(null);
  const [systemChoices, setSystemChoices] = useState([]);
  const [questionCitations, setQuestionCitations] = useState([]);
  const [loading, setLoading] = useState(false);

  const { inspectionUid } = useParams();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const [responseInspection] = await Promise.all([inspectionApi.get(`/inspections/${inspectionUid}`)]);

      setInspection(responseInspection.data.body[0]);
      setSystemChoices(responseInspection.data.body[0][systemChoiceField]);
      setQuestionCitations(responseInspection?.data.body[0]?.protocol_quality);

      setLoading(false);
    })();
  }, [inspectionUid, systemChoiceField]);

  const onDragEnd = async (result) => {
    const { destination, source, draggableId, type } = result;
    if (!destination) return; // drop to outside droppable context
    if (destination.droppableId === source.droppableId && destination.index === source.index) return; // user drop back to start position

    if (type === "systemChoice") {
      // Update draggable destination
      const newSystemChoices = Array.from(systemChoices);
      newSystemChoices.splice(source.index, 1); // remove source from array
      const newSystemChoice = systemChoices.find((systemChoice) => systemChoice?.code_table_uid === draggableId);
      newSystemChoices.splice(destination.index, 0, newSystemChoice); // insert destination to array

      // Update state
      setSystemChoices(newSystemChoices);

      // Save new systemChoice of inspection to database
      inspection[systemChoiceField] = newSystemChoices;
    } else if (type === "discussion" || type === "observation") {
      // Find the dragging questionCitation
      const foundQuestionCitation = questionCitations.find((questionCitation) => questionCitation?.code === draggableId);

      // Filter all questionCitations of found questionCitation's system choice
      const questionCitationsBySystemChoice = questionCitations.filter((questionCitation) => questionCitation?.detail.systemChoice === foundQuestionCitation.detail.systemChoice && questionCitation?.detail.coverageChoice === coverageChoiceGuid);

      // Perform swap
      const newQuestionCitations = Array.from(questionCitationsBySystemChoice);
      newQuestionCitations.splice(source.index, 1); // remove source from array
      const newQuestionCitation = questionCitations.find((questionCitation) => questionCitation?.code === draggableId);
      newQuestionCitations.splice(destination.index, 0, newQuestionCitation); // insert destination to array

      // Replace existing questionCitations's system choice with the new ones
      const questionCitationsExcludedSwapOnes = questionCitations.filter((questionCitation) => newQuestionCitations.filter((item) => item.code === questionCitation.code).length <= 0);
      const finalQuestionCitations = [...questionCitationsExcludedSwapOnes, ...newQuestionCitations];
      setQuestionCitations(finalQuestionCitations);

      // Save new systemChoice of inspection to database
      inspection.protocol_quality = finalQuestionCitations;
    }
    await inspectionApi.put(`/inspections/${inspection.inspection_uid}`, inspection);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box m="10px">
        <Button variant="contained" color="primary" startIcon={<PrintIcon />} href={`/inspection/${inspectionUid}/${coverageDndType.toLowerCase() === REPORT_TYPE.OBSERVATION.toLowerCase() ? "printcitation" : "printdiscussion"}`}>
          Print {coverageDndType}
        </Button>
      </Box>
      <Box m="10px">
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <SystemChoiceDroppable systemChoices={systemChoices} questionCitations={questionCitations} coverageDndType={coverageDndType} coverageChoice={coverageChoiceGuid} />
      </Box>
    </DragDropContext>
  );
};

export default CoverageDnD;
