import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import tenantApi from "../apis/tenantApi";

export const useTenants = () => {
  const [tenants, setTenants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const idToken = useSelector((state) => {
    return state.token;
  });

  const fetchTenants = async () => {
    setIsLoading(true);
    try {
      const requestHeader = {
        headers: {
          Authorization: idToken,
        },
      };
      const response = await tenantApi.get(`/tenants`, requestHeader);
      if (response && response.data) setTenants(response.data.body);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTenants();
    // eslint-disable-next-line  
  }, []);

  const deleteTenant = async (tenant) => {
    try {
      setIsLoading(true);
      await tenantApi.delete(`/tenants/${tenant.tenant_uid}`);
      setTenants(tenants.filter((item) => item.tenant_uid !== tenant.tenant_uid));
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  return { tenants, deleteTenant, isLoading };
};
