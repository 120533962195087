import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import { Alert, AlertTitle, Box, CircularProgress, FormControl, Grid, IconButton, InputLabel, Link, Select, MenuItem, Tooltip, Typography, useTheme, TextField, Button, Checkbox } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

import { tokens } from "../../theme";
import { deleteVectorIDsByFilter, scrapingWebsiteFormUrl, uploadFileToPipecone } from "../../utilities/llm";
import { ARTIFICIAL_INTELLIGENCE, MARKET_INDENTED_LIST } from "../../utilities/constants";
import documentApi from "../../apis/documentApi";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { getLocalDateTime, handleDownloadFileFromS3 } from "../../utilities/helpers";
import ModalDialog from "../../components/ModalDialog";
import { setGlobalCommodity, setGlobalMarket } from "../../state";
import codeTablePostgresApi from "../../apis/codeTablePostgresApi";
import { EqipItem } from "../../components/EqipItem";

const AI = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const [uploadedFiles, setUploadFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [aiPipeconeDocuments, setAiPipeconeDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [markets, setMarkets] = useState([]);
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [commodities, setCommodities] = useState([]);
  const [selectedcommodities, setSelectedCommodities] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletingAiPipeconeDocument, setDeletingAiPipeconeDocument] = useState(null);
  const [websiteUrl, setWebsiteUrl] = useState(null);

  const idToken = useSelector((state) => {
    return state.token;
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchData() {
    setIsLoading(true);
    const requestHeader = {
      headers: {
        Authorization: idToken,
      },
    };

    const [responseCodeTable, responseDocumentApi] = await Promise.all([codeTablePostgresApi.get(`/codetables/codetabletypes/12,13`), documentApi.get(`/ai/pipeconedocuments`, requestHeader)]);

    setAiPipeconeDocuments(responseDocumentApi.data.body);
    const codeTables = responseCodeTable.data.body;
    setCommodities(codeTables.filter((codeTable) => codeTable.code_table_type_id === 12));
    const sortedMarkets = codeTables.filter((codeTable) => codeTable.code_table_type_id === 13)?.sort((a, b) => a.sort_order - b.sort_order);
    setMarkets(sortedMarkets);
    setIsLoading(false);
  }

  const loggedInUser = useSelector((state) => {
    return state.user;
  });

  const onDrop = useCallback(
    async (acceptedFiles) => {
      try {
        setIsLoading(true);
        setErrorMessage(null);
        if (acceptedFiles?.length > ARTIFICIAL_INTELLIGENCE.MAX_FILE_UPLOAD_TO_PIPECONE) {
          setErrorMessage(`Please upload up to ${ARTIFICIAL_INTELLIGENCE.MAX_FILE_UPLOAD_TO_PIPECONE} files`);
        } else if (selectedcommodities?.length <= 0) {
          setErrorMessage(`Please select one or more Commondities`);
        } else if (selectedMarkets?.length <= 0) {
          setErrorMessage(`Please select one or more Markets`);
        } else {
          // Do something with the files
          setUploadFiles(acceptedFiles);
          const results = await Promise.all(acceptedFiles.map(async (acceptedFile) => await uploadFileToPipecone(acceptedFile, loggedInUser.email, selectedcommodities[0], selectedMarkets[0])));
  
          const updatedFiles = acceptedFiles.map((acceptedFile) => {
            const result = results.filter((result) => acceptedFile.name === result.file.name)[0];
            if (result.status !== 200) {
              acceptedFile.isFailed = true;
              acceptedFile.error = result.error;
            } else {
              acceptedFile.isSucceed = true;
            }
            return acceptedFile;
          });
          setUploadFiles(updatedFiles);
          await fetchData();
        }
      } catch (error) {
        console.error('onDrop.error', error);
      } finally {
        setIsLoading(false);
      }

    // eslint-disable-next-line
    }, [loggedInUser.email, selectedcommodities, selectedMarkets]
  );

  const handleDownloadFile = async (attachment) => {
    handleDownloadFileFromS3(documentApi, attachment.file_name, attachment.file_name, "/ai/pipeconedocuments/presignedurldownload");
  };

  const handleCommodityChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCommodities(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );

    //setCommodity(event.target.value);
    dispatch(
      setGlobalCommodity({
        globalCommodity: typeof value === 'string' ? value.split(',') : value,
      })
    );
  };

  const handleMarketChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedMarkets(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );

    dispatch(
      setGlobalMarket({
        globalMarket: typeof value === 'string' ? value.split(',') : value,
      })
    );

  };

  const handleDeleteOk = async () => {
    setIsLoading(true);
    setErrorMessage(null);
    try {
      setIsDeleting(true);
      const namespace = `${selectedcommodities[0]}_${selectedMarkets[0]}`;
      await deleteVectorIDsByFilter(deletingAiPipeconeDocument.file_name, namespace);
      await documentApi.delete(`ai/pipeconedocuments/${deletingAiPipeconeDocument.ai_pipecone_document_uid}`);
      await fetchData();
    } catch (error) {
      console.error("handleDeleteOk", error);
      setErrorMessage(`Unable to delete AI document. ${error.response.data}`);
    } finally {
      setIsDeleting(false);
      setOpenDeleteModal(false);
      setIsLoading(false);
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteModal(false);
  };

  const handleDelete = (aiPipeconeDocument) => {
    setOpenDeleteModal(true);
    setDeletingAiPipeconeDocument(aiPipeconeDocument);
  };

  const handleCloseUploadFile = (name) => {
    setUploadFiles(uploadedFiles.filter((uploadFile) => uploadFile.name !== name));
  };

  const handleUploadWebSiteUrl = async () => {
    setIsLoading(true);
    setErrorMessage(null);
    try {
      if (selectedcommodities?.length <= 0) {
        setErrorMessage(`Please select one or more Commondities`);
      } else if (selectedMarkets?.length <= 0) {
        setErrorMessage(`Please select one or more Markets`);
      } else {
        const response = await scrapingWebsiteFormUrl(websiteUrl, loggedInUser.email, selectedcommodities[0], selectedMarkets[0]);
        if (response.status === 500) {
          setErrorMessage(`Unable to upload website. ${response.error}`); 
        } else {
          await fetchData();
        }
      }
    } catch (error) {
      console.error("handleUploadWebSiteUrl", error);
      setErrorMessage(`Unable to upload website. ${error.response.data}`);
    } finally {
      setIsLoading(false);
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const columns = [
    {
      field: "name",
      headerName: "File Name",
      flex: 1,
      width: 150,
      renderCell: ({ row }) => {
        return (
          <>
            <Tooltip title={row.name}>{row.name}</Tooltip>
          </>
        );
      },
    },
    {
      field: "file_name",
      headerName: "Full File Name",
      flex: 1,
      width: 200,
      renderCell: ({ row }) => {
        return (
          <>
            <Tooltip title={row.file_name}>
              <Link component="button" aria-label="download file" onClick={() => handleDownloadFile(row)}>
                {row.file_name}
              </Link>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "commondity_desc",
      headerName: "Commodity",
      flex: 1,
      width: 50,
    },
    {
      field: "market_desc",
      headerName: "Market",
      flex: 1,
      width: 50,
    },
    {
      field: "created_by",
      headerName: "Created By",
      flex: 1,
      width: 50,
    },
    {
      field: "created_date",
      headerName: "Created Date",
      flex: 1,
      width: 50,
      renderCell: ({ row }) => {
        return <>{getLocalDateTime(row.created_date)}</>;
      },
    },
    {
      field: "ai_pipecone_document_uid",
      headerName: "Actions",
      flex: 1,
      width: 50,
      renderCell: ({ row }) => {
        return (
          <Box>
            <IconButton color="error" onClick={() => handleDelete(row)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  return (
    <Box m="20px">
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <Box>
        <FormControl sx={{ minWidth: 300, marginTop: 1, marginBottom: 1, marginRight: 1 }}>
          <InputLabel id="commodity-label">Commodity</InputLabel>
          <Select multiple fullWidth labelId="commodity-label" label="Commodity" value={selectedcommodities} onChange={handleCommodityChange}
            renderValue={(selected) => selected.join(', ')}
          >
            {commodities.map((option) => (
              <MenuItem value={option.code}>
                <Checkbox checked={selectedcommodities.indexOf(option.code) > -1} />
                {`${option.description}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 300, marginTop: 1, marginBottom: 1 }}>
          <InputLabel id="market-label">Market</InputLabel>
          <Select multiple fullWidth labelId="market-label" label="Market" value={selectedMarkets} onChange={handleMarketChange}
            renderValue={(selected) => selected.join(', ')}
          >
            {markets.map((option) => (
              <MenuItem value={option.code}>
                {MARKET_INDENTED_LIST.includes(option.code) && <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>}
                <Checkbox checked={selectedMarkets.indexOf(option.code) > -1} />
                {`${option.description}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Box
            sx={{
              p: 2,
              border: "1px dashed grey",
              textAlign: "center",
            }}
          >
            {isDragActive ? (
              <Typography>Drop the files here ...</Typography>
            ) : (
              <Typography>
                Drag 'n' drop some files here, or click to select files. Please upload <b>pdf</b> file only
              </Typography>
            )}
          </Box>
        </div>
      </Box>
      <Box>
        <FormControl fullWidth sx={{ marginTop: 1, marginBottom: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={11}>
              <EqipItem>
                <TextField fullWidth label="Website Url" value={websiteUrl} onChange={(e) => setWebsiteUrl(e.target.value)} />
              </EqipItem>
            </Grid>
            <Grid item xs={1}>
              <Button variant="contained" onClick={handleUploadWebSiteUrl}>Upload</Button>
            </Grid>
          </Grid>
        </FormControl>
      </Box>
      <Box sx={{ marginTop: 1 }}>
        <Grid container spacing={1}>
          {uploadedFiles?.length > 0 &&
            uploadedFiles.map((uploadFile) => {
              return (
                <Grid item xs={6}>
                  {!uploadFile.isFailed && !uploadFile.isSucceed && (
                    <Alert severity="info">
                      <AlertTitle>{uploadFile.name}</AlertTitle>
                      <CircularProgress size={30} />
                    </Alert>
                  )}
                  {uploadFile.isSucceed && (
                    <Alert
                      severity="success"
                      action={
                        <IconButton onClick={() => handleCloseUploadFile(uploadFile.name)}>
                          <CloseIcon />
                        </IconButton>
                      }
                    >
                      <AlertTitle>{uploadFile.name}</AlertTitle>
                      <strong>Upload successful!</strong>
                    </Alert>
                  )}
                  {uploadFile.isFailed && (
                    <Alert
                      severity="error"
                      action={
                        <IconButton onClick={() => handleCloseUploadFile(uploadFile.name)}>
                          <CloseIcon />
                        </IconButton>
                      }
                    >
                      <AlertTitle>{uploadFile.name}</AlertTitle>
                      <strong>{uploadFile.error}</strong>
                    </Alert>
                  )}
                </Grid>
              );
            })}
        </Grid>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.blueAccent[600],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.grey[900],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.grey[900],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.grey[500]} !important`,
          },
        }}
      >
        <DataGrid rows={aiPipeconeDocuments} columns={columns} loading={isLoading} />
      </Box>
      <ModalDialog title="Delete" content="Are you sure you want to delete this item?" isLoading={isDeleting} open={openDeleteModal} handleOk={handleDeleteOk} handleCancel={handleDeleteCancel} />
    </Box>
  );
};

export default AI;
