import axios from 'axios';
import { CITATION_DISCUSSION, CITATION_LANGUAGE, CITATION_SPECIFICALLY, CITATION_SUPPORTING, 
    COVERAGE_CHOICE, COVERAGE_COMMENT, SHORT_CITATION_CODE, SYSTEM_CHOICE,
    REGULATION_CODE, 
    CITATION_LANGUAGE_DELIMETER,
    CONVERT_SQUARE_BRACKET_TO_ARRAY} from "./constants";

export const getUTCDateTime = () => {
    return (new Date()).toISOString();
};

export const getLocalDateTime = (fromISODateTime) => {
    const localDateTime= new Date(fromISODateTime);
    return `${localDateTime.toLocaleDateString()} ${localDateTime.toLocaleTimeString()}`;
};

export const getLocalDate = (fromISODateTime) => {
    const localDateTime= new Date(fromISODateTime);
    return `${localDateTime.toLocaleDateString()}`;
};

export const getDatabaseDate = (date) => {
    // Convert to UTC date
    const convertUTCDate = (new Date(date)).toUTCString();

    // Convert to YYYY-MM-DD format
    var d = new Date(convertUTCDate),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export const existRegulationWithNoCitation = (regulationCode) => {
    const regulationsWithNoCitation = [ 
        "RE0001", "RE0002", "RE0003", "RE0004", 
        // Summary
        "SM010", "SM011", "SM012", "SM013", "SM014",
        "SM020", "SM021", "SM022",
        "SM030", "SM031", "SM032", "SM033", "SM034", "SM035", "SM036",
        "SM040", "SM041",
        "SM040", "SM041",
        "SM050", "SM051",
        "SM060",
        "SM070", "SM071",
        "SM080",
        // Pai Drug
        "FDOB010", "FDOB011", "FDOB012", "FDOB013", "FDOB014",
        "FDOB020",
        "FDOB030",
        "FDOB040", "FDOB041", "FDOB042",
        // Pai API
        "APOB010", "APOB011", "APOB012", "APOB013", "APOB014",
        "APOB020",
        "APOB030",
        "APOB040", "APOB041", "APOB042"
        ];
    return regulationsWithNoCitation.includes(regulationCode);
}

// TODO: This is temporary solution, it should be done in backend and load system choice from cfr.csv file.
export const isFoundSystemChoicesBySubPartCode = (subPartCode, systemChoiceName) => {
    const subPartList = {
        SP002: ['Quality', 'Facilities/Equipment', 'Materials', 'Production', 'Packaging/Labeling', 'Laboratory'],
        SP003: ['Facilities/Equipment'],
        SP004: ['Facilities/Equipment'],
        SP005: ['Quality', 'Materials'],
        SP006: ['Quality', 'Production'],
        SP007: ['Quality', 'Packaging/Labeling'],
        SP008: ['Materials'],
        SP009: ['Quality', 'Laboratory'],
        SP010: ['Quality', 'Facilities/Equipment', 'Materials', 'Production', 'Packaging/Labeling', 'Laboratory'],
        SP011: ['Quality', 'Laboratory']
      };

    return subPartList[subPartCode]?.includes(systemChoiceName);
}

// replace citationLanguage '<#>' and [optionLabel|optionFlag] with empty and optionLabel/empty respectively
export const transformCitationLanguage = (citationLanguage)  => {
    let finalCitationLanguage = citationLanguage?.replaceAll(CITATION_LANGUAGE_DELIMETER, '');
    // store citation options in [xyz] pattern into the array
    const citationLanguageOptions = finalCitationLanguage?.match(CONVERT_SQUARE_BRACKET_TO_ARRAY); 
    citationLanguageOptions?.forEach(option => {
        const [optionLabel, optionFlag] = option.replace('[', '').split('|');
        if (optionFlag === '1') {
            finalCitationLanguage = finalCitationLanguage.replace(`${option}]`, optionLabel);
        } else {
            finalCitationLanguage = finalCitationLanguage.replace(`${option}]`, '');
        }
    })
    return finalCitationLanguage; 
}

export const shortenDescription = (description) => {
    const allowedCharacters = 100;
    return description.substring(0, allowedCharacters) + '...';
}

export const populateInspectionSummary = (fieldName, value, inspection) => {

}

export const populateInspectionProtocol = (fieldName, code, value, inspection) => {
    inspection.protocol_quality = inspection.protocol_quality || []; // initialize protocol_quality if null
    let selectedCode = inspection.protocol_quality?.find(item => item?.code === code);

    if (selectedCode) { //Edit mode
        switch (fieldName) {
            case SYSTEM_CHOICE:
                selectedCode = {
                    ...selectedCode,
                    detail: {
                        ...selectedCode.detail,
                        systemChoice: value
                    }
                }
                break;
            case COVERAGE_CHOICE:
                selectedCode = {
                    ...selectedCode,
                    detail: {
                        ...selectedCode.detail,
                        coverageChoice: value
                    }
                }
                break;
            case COVERAGE_COMMENT:
                selectedCode = {
                    ...selectedCode,
                    detail: {
                        ...selectedCode.detail,
                        coverageComment: value
                    }
                }
                break;
            case CITATION_LANGUAGE:
                selectedCode = {
                    ...selectedCode,
                    detail: {
                        ...selectedCode.detail,
                        citationLanguage: value
                    }
                }
                break;
            case CITATION_SPECIFICALLY:
                selectedCode = {
                    ...selectedCode,
                    detail: {
                        ...selectedCode.detail,
                        citationSpecifically: value
                    }
                }
                break;
            case CITATION_SUPPORTING:
                selectedCode = {
                    ...selectedCode,
                    detail: {
                        ...selectedCode.detail,
                        citationSupporting: value
                    }
                }
                break;
            case CITATION_DISCUSSION:
                selectedCode = {
                    ...selectedCode,
                    detail: {
                        ...selectedCode.detail,
                        citationDiscussion: value
                    }
                }
                break;
            case REGULATION_CODE:
                selectedCode = {
                    ...selectedCode,
                    detail: {
                        ...selectedCode.detail,
                        regulationComment: value
                    }
                }
                break;
            default:
                break;
        }
    } else { // Add mode
        switch (fieldName) {
            case SHORT_CITATION_CODE:
                selectedCode = {
                    code: code,
                    detail: {
                        ...selectedCode?.detail
                    }
                }
                break;
            case SYSTEM_CHOICE:
                selectedCode = {
                    code: code,
                    detail: {
                        ...selectedCode?.detail,
                        systemChoice: value
                    }
                }
                break;
            case COVERAGE_CHOICE:
                selectedCode = {
                    code: code,
                    detail: {
                        ...selectedCode?.detail,
                        coverageChoice: value
                    }
                }
                break;
            case COVERAGE_COMMENT:
                selectedCode = {
                    code: code,
                    detail: {
                        ...selectedCode?.detail,
                        coverageComment: value
                    }
                }
                break;
            case CITATION_LANGUAGE:
                selectedCode = {
                    code: code,
                    detail: {
                        ...selectedCode?.detail,
                        citationLanguage: value
                    }
                }
                break;
            case CITATION_SPECIFICALLY:
                selectedCode = {
                    code: code,
                    detail: {
                        ...selectedCode?.detail,
                        citationSpecifically: value
                    }
                }
                break;
            case CITATION_SUPPORTING:
                selectedCode = {
                    code: code,
                    detail: {
                        ...selectedCode?.detail,
                        citationSupporting: value
                    }
                }
                break;
            case CITATION_DISCUSSION:
                selectedCode = {
                    code: code,
                    detail: {
                        ...selectedCode?.detail,
                        citationDiscussion: value
                    }
                }
                break;
            case REGULATION_CODE:
                selectedCode = {
                    code: code,
                    detail: {
                        ...selectedCode?.detail,
                        regulationComment: value
                    }
                }
                break;
            default:
                break;
        }
    }

    inspection.protocol_quality = [...inspection.protocol_quality, selectedCode];

    // remove duplication of selected question in protocol_quality array
    if (inspection.protocol_quality) {
        inspection.protocol_quality = 
            [...inspection.protocol_quality?.reduce((map, obj) => map.set(obj?.code, obj), new Map()).values()]; // This code may cause typing text performance

    }

    return inspection.protocol_quality;
}

export const getDetailByShortCitationCode = (inspection, citation) => {
    const detail = inspection?.protocol_quality?.filter(item => item?.code === citation?.shortcitation_code)[0]?.detail;
    return detail;
}

export const getDetailByRegulationCode = (inspection, citation) => {
    const detail = inspection?.protocol_quality?.filter(item => item?.code === citation?.regulation_code)[0]?.detail;
    return detail;
}

export const getLookupDescriptionByCode = (lookups, code) => {
    const description = lookups?.filter(lookup => lookup.code === code)[0]?.description;
    return description;
}

export const getCodeTableDescriptionByCodeTableUid = (codeTables, codeTableUid) => {
    const description = codeTables?.filter(codeTable => codeTable.code_table_uid === codeTableUid)[0]?.description;
    return description;
}

export const getCitationLanguageWithoutPlaceHolder = (citationLanguage) => {
    return citationLanguage?.replaceAll('<#>', '').replaceAll('[', ' ').replaceAll('[', ' ').replaceAll('|0]', ',').replaceAll('|1]', ',');
}

export const handleDownloadFileFromS3 = async (api, s3ObjectKey, fileName, presignUrlDownload) => {
    // setLoading(true);
    try {
        // 1. Get S3 presigned url to download
        const s3PresignedUrlResult = await api.post(presignUrlDownload, {
            objectKey: `${s3ObjectKey}`,
            contentType: "image/jpg"
        });

        // 2. Download file from presigned url
        axios({
            url: s3PresignedUrlResult?.data?.body,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        });
    } catch (error) {
        console.error('Download failed', error);
        // setLoading(false);
    }

    // setLoading(false);
  }

export const setLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
}

export const getLocalStorage = (key) => {
    return localStorage.getItem(key);
}

export const getLastElementStringArray = (str, delimeter) => {
    const last = str.split(delimeter).pop();
    return last;
}
