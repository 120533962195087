import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Stack,
  Button,
  TextField,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { Navigate, useParams, Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSnackbar } from "notistack";

import Header from "../../components/Header";
import { SNACKBAT_AUTO_HIDE_DURATION } from "../../utilities/constants";
import tenantApi from "../../apis/tenantApi";

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { enqueueSnackbar } = useSnackbar();
  const [errorMessage, setErrorMessage] = useState('');
  const { tenant_id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);

  const [selectedTenant, setSelectedTenant] = useState({
    code: "",
    tenant_name: "", 
    description: "",
  });

  const loggedInUser = useSelector((state) => {
    return state.user;
  });

  const idToken = useSelector((state) => {
    return state.token;
  });

  const checkoutSchema = yup.object().shape({
    code: yup.string(),
    tenant_name: yup.string().required("required"),
    description: yup.string(),
  });

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    const saveTenant = {
      code: values.code,
      tenant_name: values.tenant_name,
      description: values.description,
    };

    try {
      const requestHeader = {
        headers: {
          "Authorization": idToken
        },
      };

      if (tenant_id) {
        const editedTenant = {
          ...saveTenant,
          updated_by: loggedInUser.email,
        };
        await tenantApi.put(`/tenants/${tenant_id}`, editedTenant, requestHeader);
      } else {
        const newTenant = {
          ...saveTenant,
          created_by: loggedInUser.email,
        };
        
        await tenantApi.post("/tenants/", newTenant, requestHeader);
      }

      enqueueSnackbar("The tenant save successfully!", {
        variant: "success",
        autoHideDuration: SNACKBAT_AUTO_HIDE_DURATION,
      });
      setIsRedirect(true);
      setIsLoading(false);
    } catch (err) {
      // Handle Error Here
      setErrorMessage(`Failed to create/update a Tenant. Error: ${err}`)
      setIsLoading(false);
      console.error(err);
    }
  };

  const fetchData = async () => {
    if (tenant_id) {
      setIsLoading(true);
      try {
        const [responseTenant] =
          await Promise.all([
            tenantApi.get(`/tenants/${tenant_id}`)
          ]);
          setSelectedTenant(responseTenant.data.body[0]);
  
        setIsLoading(false);
      } catch (err) {
        // Handle Error Here
        setIsLoading(false);
        console.error(err);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box m="20px">
      {isRedirect && <Navigate to="/tenants" />}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Header title="TENANT" subtitle="Create/Update a Tenant" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={selectedTenant}
        validationSchema={checkoutSchema}
        enableReinitialize={true} // Important: allow to reload data on useEffect
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              {errorMessage.length > 0 && 
              <Alert onClose={() => setErrorMessage('')} 
                severity="error"
                sx={{ gridColumn: "span 4" }}
              >
                {errorMessage}
              </Alert>
              }
              <TextField
                fullWidth
                type="text"
                label="Code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.code}
                name="code"
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                type="text"
                label="Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.tenant_name}
                name="tenant_name"
                error={!!touched.tenant_name && !!errors.tenant_name}
                helperText={touched.tenant_name && errors.tenant_name}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                type="text"
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                sx={{ gridColumn: "span 2" }}
              />

            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Stack direction="row" spacing={1}>
                <Button
                  type="submit"
                  color="info"
                  variant="outlined"
                  component={Link}
                  to="/tenants"
                  startIcon={<CancelIcon />}
                  onClick={() => setSubmitting(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isLoading}
                  startIcon={<SaveIcon />}
                >
                  Save
                </Button>
              </Stack>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Form;
