import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// mui import
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, CircularProgress } from "@mui/material";
import "react-pro-sidebar/dist/css/styles.css";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

// project import
import { tokens } from "../../theme";
import { existRegulationWithNoCitation } from "../../utilities/helpers";
import assignmentApi from "../../apis/assignmentApi";
import lookupApi from "../../apis/lookupApi";
import { LOOKUP_CATEGORIES, LOOKUP_CFR_TYPES } from "../../utilities/constants";
import Item from "./item";
import General from "./general";
import Pai from "./pai";
import Practice from "./SideBar/practice";

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLoading] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const [generals, setGenerals] = useState([]);
  const [paiDrugs, setPaiDrugs] = useState([]);
  const [paiApis, setPaiApis] = useState([]);
  const [sections, setSections] = useState([]);
  const [subSections, setSubsections] = useState([]);
  const [regulations, setRegulations] = useState([]);
  const [citationLanguages, setcitationLanguages] = useState([]);
  
  // Q7
  const [sectionsQ7, setSectionsQ7] = useState([]);
  const [subSectionsQ7, setSubsectionsQ7] = useState([]);
  const [regulationsQ7, setRegulationsQ7] = useState([]);
  const [citationLanguagesQ7, setcitationLanguagesQ7] = useState([]);

  // Cfr111
  const [sectionsCfr111, setSectionsCfr111] = useState([]);
  const [subSectionsCfr111, setSubsectionsCfr111] = useState([]);
  const [regulationsCfr111, setRegulationsCfr111] = useState([]);
  const [citationLanguagesCfr111, setcitationLanguagesCfr111] = useState([]);

  // Cfr820
  const [sectionsCfr820, setSectionsCfr820] = useState([]);
  const [subSectionsCfr820, setSubsectionsCfr820] = useState([]);
  const [regulationsCfr820, setRegulationsCfr820] = useState([]);
  const [citationLanguagesCfr820, setcitationLanguagesCfr820] = useState([]);

  // Cfr820211
  const [sectionsCfr820211, setSectionsCfr820211] = useState([]);
  const [subSectionsCfr820211, setSubsectionsCfr820211] = useState([]);
  const [regulationsCfr820211, setRegulationsCfr820211] = useState([]);
  const [citationLanguagesCfr820211, setcitationLanguagesCfr820211] = useState([]);

  // Cfr211820
  const [sectionsCfr211820, setSectionsCfr211820] = useState([]);
  const [subSectionsCfr211820, setSubsectionsCfr211820] = useState([]);
  const [regulationsCfr211820, setRegulationsCfr211820] = useState([]);
  const [citationLanguagesCfr211820, setcitationLanguagesCfr211820] = useState([]);

  const [assignment, setAssignment] = useState(null);

  const inspectionUid = useSelector((state) => {
    return state.inspectionUid;
  });

  const assignmentUid = useSelector((state) => {
    return state.assignmentUid;
  });

  const idToken = useSelector((state) => {
    return state.token;
  });

  let subPart = ""; // hold subpart indicator to show its sections
  let subPartCode = "";
  // Q7
  let subPartQ7 = ""; // hold subpart indicator to show its sections
  let subPartCodeQ7 = "";

  // Cfr111
  let subPartCfr111 = ""; // hold subpart indicator to show its sections
  let subPartCodeCfr111 = "";
  
  // Cfr820
  let subPartCfr820 = ""; // hold subpart indicator to show its sections
  let subPartCodeCfr820 = "";

  // Cfr820211
  let subPartCfr820211 = ""; // hold subpart indicator to show its sections
  let subPartCodeCfr820211 = "";

  // Cfr211820
  let subPartCfr211820 = ""; // hold subpart indicator to show its sections
  let subPartCodeCfr211820 = "";

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const requestHeader = {
          headers: {
            "Authorization": idToken
          },
        };

        const [responseGeneral, responsePaiDrug, responsePaiApi, responseSection, responseSubsection, responseRegulation, responseCitationLanguage, 
          // Q7
          responseSectionQ7, responseSubsectionQ7, responseRegulationQ7, responseCitationLanguageQ7,
          // Cfr111
          responseSectionCfr111, responseSubsectionCfr111, responseRegulationCfr111, responseCitationLanguageCfr111,
          // Cfr820
          responseSectionCfr820, responseSubsectionCfr820, responseRegulationCfr820, responseCitationLanguageCfr820,
          // Cfr820211
          responseSectionCfr820211, responseSubsectionCfr820211, responseRegulationCfr820211, responseCitationLanguageCfr820211,
          // Cfr211820
          responseSectionCfr211820, responseSubsectionCfr211820, responseRegulationCfr211820, responseCitationLanguageCfr211820,
          responseAssignment] = await Promise.all([
          lookupApi.get(`/lookups/categories/${LOOKUP_CATEGORIES.GENERAL}`, requestHeader), // get all generals
          lookupApi.get(`/lookups/categories/${LOOKUP_CATEGORIES.PAI_DRUG}`, requestHeader), // get all pai drug
          lookupApi.get(`/lookups/categories/${LOOKUP_CATEGORIES.PAI_API}`, requestHeader), // get all pai api
          lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SECTION}/lookupcodetypes/CFR211`, requestHeader), // get all sections
          lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SUB_SECTION}/lookupcodetypes/CFR211`, requestHeader), // get all sub sections
          lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.REGULATION}/lookupcodetypes/CFR211`, requestHeader), // get all regulations
          lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SHORT_CITATION_LANGUAGE}/lookupcodetypes/CFR211`, requestHeader), // get all citation languages
          lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SECTION}/lookupcodetypes/Q7API`, requestHeader), // get all sections
          lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SUB_SECTION}/lookupcodetypes/Q7API`, requestHeader), // get all sub sections
          lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.REGULATION}/lookupcodetypes/Q7API`, requestHeader), // get all regulations
          lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SHORT_CITATION_LANGUAGE}/lookupcodetypes/Q7API`, requestHeader), // get all citation languages
          lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SECTION}/lookupcodetypes/CFR111`, requestHeader), // get all sections
          lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SUB_SECTION}/lookupcodetypes/CFR111`, requestHeader), // get all sub sections
          lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.REGULATION}/lookupcodetypes/CFR111`, requestHeader), // get all regulations
          lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SHORT_CITATION_LANGUAGE}/lookupcodetypes/CFR111`, requestHeader), // get all citation languages
          lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SECTION}/lookupcodetypes/CFR820`, requestHeader), // get all sections
          lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SUB_SECTION}/lookupcodetypes/CFR820`, requestHeader), // get all sub sections
          lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.REGULATION}/lookupcodetypes/CFR820`, requestHeader), // get all regulations
          lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SHORT_CITATION_LANGUAGE}/lookupcodetypes/CFR820`, requestHeader), // get all citation languages
          lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SECTION}/lookupcodetypes/CFR820211`, requestHeader), // get all sections
          lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SUB_SECTION}/lookupcodetypes/CFR820211`, requestHeader), // get all sub sections
          lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.REGULATION}/lookupcodetypes/CFR820211`, requestHeader), // get all regulations
          lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SHORT_CITATION_LANGUAGE}/lookupcodetypes/CFR820211`, requestHeader), // get all citation languages
          lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SECTION}/lookupcodetypes/CFR211820`, requestHeader), // get all sections
          lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SUB_SECTION}/lookupcodetypes/CFR211820`, requestHeader), // get all sub sections
          lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.REGULATION}/lookupcodetypes/CFR211820`, requestHeader), // get all regulations
          lookupApi.get(`/lookups/lookuptype/${LOOKUP_CFR_TYPES.SHORT_CITATION_LANGUAGE}/lookupcodetypes/CFR211820`, requestHeader), // get all citation languages
          assignmentApi.get(`/assignments/${assignmentUid}`), // get an assignment
        ]);

        setGenerals(responseGeneral.data.body);
        setPaiDrugs(responsePaiDrug.data.body);
        setPaiApis(responsePaiApi.data.body);

        // cfr211
        setSections(responseSection.data.body);
        setSubsections(responseSubsection.data.body);
        setRegulations(responseRegulation.data.body);
        setcitationLanguages(responseCitationLanguage.data.body);

        // Q7
        setSectionsQ7(responseSectionQ7.data.body);
        setSubsectionsQ7(responseSubsectionQ7.data.body);
        setRegulationsQ7(responseRegulationQ7.data.body);
        setcitationLanguagesQ7(responseCitationLanguageQ7.data.body);

        // cfr111
        setSectionsCfr111(responseSectionCfr111.data.body);
        setSubsectionsCfr111(responseSubsectionCfr111.data.body);
        setRegulationsCfr111(responseRegulationCfr111.data.body);
        setcitationLanguagesCfr111(responseCitationLanguageCfr111.data.body);

        // Cfr820
        setSectionsCfr820(responseSectionCfr820.data.body);
        setSubsectionsCfr820(responseSubsectionCfr820.data.body);
        setRegulationsCfr820(responseRegulationCfr820.data.body);
        setcitationLanguagesCfr820(responseCitationLanguageCfr820.data.body);

        // Cfr820211
        setSectionsCfr820211(responseSectionCfr820211.data.body);
        setSubsectionsCfr820211(responseSubsectionCfr820211.data.body);
        setRegulationsCfr820211(responseRegulationCfr820211.data.body);
        setcitationLanguagesCfr820211(responseCitationLanguageCfr820211.data.body);

        // Cfr211820
        setSectionsCfr211820(responseSectionCfr211820.data.body);
        setSubsectionsCfr211820(responseSubsectionCfr211820.data.body);
        setRegulationsCfr211820(responseRegulationCfr211820.data.body);
        setcitationLanguagesCfr211820(responseCitationLanguageCfr211820.data.body);

        setAssignment(responseAssignment.data.body[0]);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    })();
  }, [inspectionUid, assignmentUid, idToken]);

  const filterRegulations = (subSectionParentUid) => {
    const filteredSubSections = subSections.filter((subSectionItem) => subSectionItem.parent_uid === subSectionParentUid);

    const filteredRegulations = filteredSubSections.map((filteredSubSectionItem) => {
      const foundRegulation = regulations.find((regulationItem) => regulationItem.parent_uid === filteredSubSectionItem.lookup_uid);
      return {
        lookup_uid: filteredSubSectionItem.lookup_uid,
        code: filteredSubSectionItem.code,
        description: filteredSubSectionItem.description,
        parent_uid: filteredSubSectionItem.parent_uid,
        description_with_regulation: foundRegulation?.parent_description + " " + foundRegulation?.description,
        regulation_code: foundRegulation?.code,
        regulation_lookup_uid: foundRegulation?.lookup_uid,
      };
    });

    return filteredRegulations;
  };

  const filterCitationLanguages = (citationLanguageParentUid) => {
    return citationLanguages.filter((citationLanguageItem) => citationLanguageItem.parent_uid === citationLanguageParentUid);
  };

  // Q7
  const filterRegulationsQ7 = (subSectionParentUid) => {
    const filteredSubSections = subSectionsQ7.filter((subSectionItem) => subSectionItem.parent_uid === subSectionParentUid);

    const filteredRegulations = filteredSubSections.map((filteredSubSectionItem) => {
      const foundRegulation = regulationsQ7.find((regulationItem) => regulationItem.parent_uid === filteredSubSectionItem.lookup_uid);
      return {
        lookup_uid: filteredSubSectionItem.lookup_uid,
        code: filteredSubSectionItem.code,
        description: filteredSubSectionItem.description,
        parent_uid: filteredSubSectionItem.parent_uid,
        description_with_regulation: foundRegulation?.parent_description + " " + foundRegulation?.description,
        regulation_code: foundRegulation?.code,
        regulation_lookup_uid: foundRegulation?.lookup_uid,
      };
    });

    return filteredRegulations;
  };

  const filterCitationLanguagesQ7 = (citationLanguageParentUid) => {
    return citationLanguagesQ7.filter((citationLanguageItem) => citationLanguageItem.parent_uid === citationLanguageParentUid);
  };

  // Cfr111
  const filterRegulationsCfr111 = (subSectionParentUid) => {
    const filteredSubSections = subSectionsCfr111.filter((subSectionItem) => subSectionItem.parent_uid === subSectionParentUid);

    const filteredRegulations = filteredSubSections.map((filteredSubSectionItem) => {
      const foundRegulation = regulationsCfr111.find((regulationItem) => regulationItem.parent_uid === filteredSubSectionItem.lookup_uid);
      return {
        lookup_uid: filteredSubSectionItem.lookup_uid,
        code: filteredSubSectionItem.code,
        description: filteredSubSectionItem.description,
        parent_uid: filteredSubSectionItem.parent_uid,
        description_with_regulation: foundRegulation?.parent_description + " " + foundRegulation?.description,
        regulation_code: foundRegulation?.code,
        regulation_lookup_uid: foundRegulation?.lookup_uid,
      };
    });

    return filteredRegulations;
  };

  const filterCitationLanguagesCfr111 = (citationLanguageParentUid) => {
    return citationLanguagesCfr111.filter((citationLanguageItem) => citationLanguageItem.parent_uid === citationLanguageParentUid);
  };

  // Cfr820
  const filterRegulationsCfr820 = (subSectionParentUid) => {
    const filteredSubSections = subSectionsCfr820.filter((subSectionItem) => subSectionItem.parent_uid === subSectionParentUid);

    const filteredRegulations = filteredSubSections.map((filteredSubSectionItem) => {
      const foundRegulation = regulationsCfr820.find((regulationItem) => regulationItem.parent_uid === filteredSubSectionItem.lookup_uid);
      return {
        lookup_uid: filteredSubSectionItem.lookup_uid,
        code: filteredSubSectionItem.code,
        description: filteredSubSectionItem.description,
        parent_uid: filteredSubSectionItem.parent_uid,
        description_with_regulation: foundRegulation?.parent_description + " " + foundRegulation?.description,
        regulation_code: foundRegulation?.code,
        regulation_lookup_uid: foundRegulation?.lookup_uid,
      };
    });

    return filteredRegulations;
  };

  const filterCitationLanguagesCfr820 = (citationLanguageParentUid) => {
    return citationLanguagesCfr820.filter((citationLanguageItem) => citationLanguageItem.parent_uid === citationLanguageParentUid);
  };

  // Cfr820211
  const filterRegulationsCfr820211 = (subSectionParentUid) => {
    const filteredSubSections = subSectionsCfr820211.filter((subSectionItem) => subSectionItem.parent_uid === subSectionParentUid);

    const filteredRegulations = filteredSubSections.map((filteredSubSectionItem) => {
      const foundRegulation = regulationsCfr820211.find((regulationItem) => regulationItem.parent_uid === filteredSubSectionItem.lookup_uid);
      return {
        lookup_uid: filteredSubSectionItem.lookup_uid,
        code: filteredSubSectionItem.code,
        description: filteredSubSectionItem.description,
        parent_uid: filteredSubSectionItem.parent_uid,
        description_with_regulation: foundRegulation?.parent_description + " " + foundRegulation?.description,
        regulation_code: foundRegulation?.code,
        regulation_lookup_uid: foundRegulation?.lookup_uid,
      };
    });

    return filteredRegulations;
  };

  const filterCitationLanguagesCfr820211 = (citationLanguageParentUid) => {
    return citationLanguagesCfr820211.filter((citationLanguageItem) => citationLanguageItem.parent_uid === citationLanguageParentUid);
  };

    // Begin Cfr211820
    const filterRegulationsCfr211820 = (subSectionParentUid) => {
      const filteredSubSections = subSectionsCfr211820.filter((subSectionItem) => subSectionItem.parent_uid === subSectionParentUid);
  
      const filteredRegulations = filteredSubSections.map((filteredSubSectionItem) => {
        const foundRegulation = regulationsCfr211820.find((regulationItem) => regulationItem.parent_uid === filteredSubSectionItem.lookup_uid);
        return {
          lookup_uid: filteredSubSectionItem.lookup_uid,
          code: filteredSubSectionItem.code,
          description: filteredSubSectionItem.description,
          parent_uid: filteredSubSectionItem.parent_uid,
          description_with_regulation: foundRegulation?.parent_description + " " + foundRegulation?.description,
          regulation_code: foundRegulation?.code,
          regulation_lookup_uid: foundRegulation?.lookup_uid,
        };
      });
  
      return filteredRegulations;
    };
  
    const filterCitationLanguagesCfr211820 = (citationLanguageParentUid) => {
      return citationLanguagesCfr211820.filter((citationLanguageItem) => citationLanguageItem.parent_uid === citationLanguageParentUid);
    };
    // End Cfr211820

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.grey[1000]} !important`,
          borderRight: "1px solid",
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: `${colors.blueAccent[200]} !important`,
        },
        "& .pro-menu-item.active": {
          color: `${colors.blueAccent[500]} !important`,
          backgroundColor: `${colors.blueAccent[100]} !important`,
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        {isLoading ? (
          <CircularProgress
            style={{
              margin: "10px 0 20px 100px",
            }}
          />
        ) : (
          <Menu iconShape="square">
            {/* LOGO AND MENU ICON */}
            <MenuItem
              onClick={() => setIsCollapsed(!isCollapsed)}
              icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
              style={{
                margin: "10px 0 20px 0",
                color: colors.grey[100],
              }}
            >
              {!isCollapsed && (
                <Box display="flex" justifyContent="space-between">
                  <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                    <MenuOutlinedIcon />
                  </IconButton>
                  <Typography variant="h5" color={colors.grey[100]} style={{ marginTop: "0.5em" }}>
                    {assignment?.subject}
                  </Typography>
                </Box>
              )}
            </MenuItem>

            <Box>
              {/* <Box style={{
                  color: colors.grey[100],
                  backgroundColor: colors.blueAccent[100],
                }}>
                <Typography variant="h5" sx={{ m: "0 0 0 10px" }}>
                  DRUGS
                </Typography>
              </Box> */}
              <SubMenu
                key="drugs"
                title="DRUGS"
                style={{
                  color: colors.grey[100],
                }}
              >
              {/* USFDA */}
                <SubMenu
                  key="usfda"
                  title="USFDA"
                  style={{
                    color: colors.grey[100],
                  }}
                >
                  <SubMenu
                    key="general"
                    title="General Information"
                    style={{
                      color: colors.grey[100],
                    }}
                  >
                    <General generals={generals} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} />
                  </SubMenu>
                  <SubMenu
                    key="paiDrug"
                    title="PAI - Finished Drug"
                    style={{
                      color: colors.grey[100],
                    }}
                  >
                    <Pai paiData={paiDrugs} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} />
                  </SubMenu>
                  <SubMenu
                    key="paiAPI"
                    title="PAI - API"
                    style={{
                      color: colors.grey[100],
                    }}
                  >
                    <Pai paiData={paiApis} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} />
                  </SubMenu>
                  <Practice lookupcodetype="CFR211" title="CFR 211" sections={sections} subPart={subPart} subPartCode={subPartCode} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} colors={colors} filterRegulations={filterRegulations} existRegulationWithNoCitation={existRegulationWithNoCitation} filterCitationLanguages={filterCitationLanguages} />
                  <SubMenu
                    key="CFR212"
                    title="CFR 212"
                    style={{
                      color: colors.grey[100],
                    }}
                  ></SubMenu>
                  <Practice lookupcodetype="Q7API" title="Q7 - API" sections={sectionsQ7} subPart={subPartQ7} subPartCode={subPartCodeQ7} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} colors={colors} filterRegulations={filterRegulationsQ7} existRegulationWithNoCitation={existRegulationWithNoCitation} filterCitationLanguages={filterCitationLanguagesQ7} />
                  <Practice lookupcodetype="CFR211820" title="CFR 211820" sections={sectionsCfr211820} subPart={subPartCfr211820} subPartCode={subPartCodeCfr211820} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} colors={colors} filterRegulations={filterRegulationsCfr211820} existRegulationWithNoCitation={existRegulationWithNoCitation} filterCitationLanguages={filterCitationLanguagesCfr211820} />
                </SubMenu>
                {/* Europe */}
                <SubMenu
                  key="eufda"
                  title="EU"
                  style={{
                    color: colors.grey[100],
                  }}
                >
                  <SubMenu
                    key="general"
                    title="General Information"
                    style={{
                      color: colors.grey[100],
                    }}
                  ></SubMenu>
                </SubMenu>
                {/* KOREA */}
                <SubMenu
                  key="krfda"
                  title="KOREA MFD"
                  style={{
                    color: colors.grey[100],
                  }}
                >
                  <SubMenu
                    key="general"
                    title="General Information"
                    style={{
                      color: colors.grey[100],
                    }}
                  ></SubMenu>
                </SubMenu>
                {/* CANADA */}
                <SubMenu
                  key="krfda"
                  title="Health Canada"
                  style={{
                    color: colors.grey[100],
                  }}
                >
                  <SubMenu
                    key="general"
                    title="General Information"
                    style={{
                      color: colors.grey[100],
                    }}
                  ></SubMenu>
                </SubMenu>
              </SubMenu>
            </Box>
            <Box>
              {/* <Box style={{
                  color: colors.grey[100],
                  backgroundColor: colors.blueAccent[100],
                }}>
                <Typography variant="h5" color={colors.grey[100]} sx={{ m: "5px 0 0 10px" }}>
                  MEDICAL DEVICES
                </Typography>
              </Box> */}
              <SubMenu
                key="medicalDevices"
                title="MEDICAL DEVICES"
                style={{
                  color: colors.grey[100],
                }}
              >
                {/* USFDA */}
                <SubMenu
                  key="usfda"
                  title="USFDA"
                  style={{
                    color: colors.grey[100],
                  }}
                >
                  <SubMenu
                    key="general"
                    title="General Information"
                    style={{
                      color: colors.grey[100],
                    }}
                  >
                    <General generals={generals} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} />   
                  </SubMenu>
                  <Practice lookupcodetype="CFR820" title="CFR 820" sections={sectionsCfr820} subPart={subPartCfr820} subPartCode={subPartCodeCfr820} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} colors={colors} filterRegulations={filterRegulationsCfr820} existRegulationWithNoCitation={existRegulationWithNoCitation} filterCitationLanguages={filterCitationLanguagesCfr820} />
                  <Practice lookupcodetype="CFR820211" title="CFR 820211" sections={sectionsCfr820211} subPart={subPartCfr820211} subPartCode={subPartCodeCfr820211} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} colors={colors} filterRegulations={filterRegulationsCfr820211} existRegulationWithNoCitation={existRegulationWithNoCitation} filterCitationLanguages={filterCitationLanguagesCfr820211} />
                </SubMenu>
                {/* Europe */}
                <SubMenu
                  key="eufda"
                  title="EU"
                  style={{
                    color: colors.grey[100],
                  }}
                >
                  <SubMenu
                    key="general"
                    title="General Information"
                    style={{
                      color: colors.grey[100],
                    }}
                  ></SubMenu>
                </SubMenu>
                {/* KOREA */}
                <SubMenu
                  key="krfda"
                  title="KOREA MFD"
                  style={{
                    color: colors.grey[100],
                  }}
                >
                  <SubMenu
                    key="general"
                    title="General Information"
                    style={{
                      color: colors.grey[100],
                    }}
                  ></SubMenu>
                </SubMenu>
                {/* CANADA */}
                <SubMenu
                  key="krfda"
                  title="Health Canada"
                  style={{
                    color: colors.grey[100],
                  }}
                >
                  <SubMenu
                    key="general"
                    title="General Information"
                    style={{
                      color: colors.grey[100],
                    }}
                  ></SubMenu>
                </SubMenu>
              </SubMenu>
            </Box>
            <Box>
              {/* <Box style={{
                  color: colors.grey[100],
                  backgroundColor: colors.blueAccent[100],
                }}>
                <Typography variant="h5" color={colors.grey[100]} sx={{ m: "5px 0 0 10px" }}>
                  FOOD
                </Typography>
              </Box> */}
              <SubMenu
                key="food"
                title="FOOD"
                style={{
                  color: colors.grey[100],
                }}
              >
                {/* USFDA */}
                <SubMenu
                  key="usfda"
                  title="USFDA"
                  style={{
                    color: colors.grey[100],
                  }}
                >
                  <SubMenu
                    key="general"
                    title="General Information"
                    style={{
                      color: colors.grey[100],
                    }}
                  >
                    <General generals={generals} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} />
                  </SubMenu>
                  <Practice lookupcodetype="CFR111" title="CFR 111" sections={sectionsCfr111} subPart={subPartCfr111} subPartCode={subPartCodeCfr111} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} colors={colors} filterRegulations={filterRegulationsCfr111} existRegulationWithNoCitation={existRegulationWithNoCitation} filterCitationLanguages={filterCitationLanguagesCfr111} />
                </SubMenu>
              </SubMenu>
              <SubMenu
                key="biologis"
                title="BIOLOGICS"
                style={{
                  color: colors.grey[100],
                }}
              >
                {/* USFDA */}
                <SubMenu
                  key="usfda"
                  title="USFDA"
                  style={{
                    color: colors.grey[100],
                  }}
                >
                  <SubMenu
                    key="general"
                    title="General Information"
                    style={{
                      color: colors.grey[100],
                    }}
                  >
                    {/* <General generals={generals} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} /> */}
                  </SubMenu>
                </SubMenu>
              </SubMenu>
            </Box>
            <Box>
              {/* <Box style={{
                  color: colors.grey[100],
                  backgroundColor: colors.blueAccent[100],
                }}>
                <Typography variant="h5" color={colors.grey[100]} sx={{ m: "5px 0 0 10px" }}>
                  FOOD
                </Typography>
              </Box> */}
              <SubMenu
                key="comprod"
                title="COMBO PRODUCTS"
                style={{
                  color: colors.grey[100],
                }}
              >
                {/* USFDA */}
                <SubMenu
                  key="usfda"
                  title="USFDA"
                  style={{
                    color: colors.grey[100],
                  }}
                >
                  <SubMenu
                    key="general"
                    title="General Information"
                    style={{
                      color: colors.grey[100],
                    }}
                  >
                    {/* <General generals={generals} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} /> */}
                  </SubMenu>
                  {/* <Practice lookupcodetype="CFR111" title="CFR 111" sections={sectionsCfr111} subPart={subPartCfr111} subPartCode={subPartCodeCfr111} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} colors={colors} filterRegulations={filterRegulationsCfr111} existRegulationWithNoCitation={existRegulationWithNoCitation} filterCitationLanguages={filterCitationLanguagesCfr111} /> */}
                </SubMenu>
              </SubMenu>
              <SubMenu
                key="DISSUP"
                title="DIETARY SUPPLEMENTS"
                style={{
                  color: colors.grey[100],
                }}
              >
                {/* USFDA */}
                <SubMenu
                  key="usfda"
                  title="USFDA"
                  style={{
                    color: colors.grey[100],
                  }}
                >
                  <SubMenu
                    key="general"
                    title="General Information"
                    style={{
                      color: colors.grey[100],
                    }}
                  >
                    {/* <General generals={generals} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} /> */}
                  </SubMenu>
                </SubMenu>
              </SubMenu>
              <SubMenu
                key="cos"
                title="COSMETICS"
                style={{
                  color: colors.grey[100],
                }}
              >
                {/* USFDA */}
                <SubMenu
                  key="usfda"
                  title="USFDA"
                  style={{
                    color: colors.grey[100],
                  }}
                >
                  <SubMenu
                    key="general"
                    title="General Information"
                    style={{
                      color: colors.grey[100],
                    }}
                  >
                    {/* <General generals={generals} inspectionUid={inspectionUid} selected={selected} setSelected={setSelected} /> */}
                  </SubMenu>
                </SubMenu>
              </SubMenu>
            </Box>
            <Box>
              {/* <Box style={{
                  color: colors.grey[100],
                  backgroundColor: colors.blueAccent[100],
                }}>
                <Typography variant="h5" color={colors.grey[100]} sx={{ m: "5px 0 0 10px" }}>
                  REPORTS
                </Typography>
              </Box> */}
              <SubMenu
                key="reports"
                title="REPORTS"
                style={{
                  color: colors.grey[100],
                }}
              >
                <Item title="Inspection" to={`/inspection/${inspectionUid}/inspectionreport`} selected={selected} setSelected={setSelected} />
                <Item title="Observation" to={`/inspection/${inspectionUid}/observationordering`} selected={selected} setSelected={setSelected} />
                {/* <Item title="Observation Old" to={`/inspection/${inspectionUid}/citationreport`} selected={selected} setSelected={setSelected} /> */}
                {/* <Item title="Discussion Old" to={`/inspection/${inspectionUid}/discussionreport`} selected={selected} setSelected={setSelected} /> */}
                <Item title="Discussion" to={`/inspection/${inspectionUid}/discussionordering`} selected={selected} setSelected={setSelected} />
              </SubMenu>
            </Box>
          </Menu>
        )}
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
