import * as React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";  
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Paper, InputBase, IconButton, CircularProgress, FormControl, InputLabel, Select, MenuItem, Checkbox } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { preditMessageWithReferences } from "../../utilities/llm";
import AskMeAnswer from "./AskMeAnswer";
import codeTablePostgresApi from "../../apis/codeTablePostgresApi";
import { MARKET_INDENTED_LIST } from "../../utilities/constants";

export default function AskMeDialog({ open, setOpen }) {
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [sourceDocuments, setSourceDocuments] = useState([]);
  const [commodities, setCommodities] = useState([]);
  // const [commodity, setCommodity] = useState(null);
  const [selectedCommodities, setSelectedCommodities] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [selectedMarkets, setSelectedMarkets] = useState([]);

  const globalCommodity = useSelector((state) => {
    return state.globalCommodity;
  }); 

  const globalMarket = useSelector((state) => {
    return state.globalMarket;
  }); 

  useEffect(() => {
    fetchData();

    setSelectedCommodities(globalCommodity);
    setSelectedMarkets(globalMarket);
  }, [globalCommodity, globalMarket]);

  async function fetchData() {
    const [responseCodeTable] =
    await Promise.all([
      codeTablePostgresApi.get(`/codetables/codetabletypes/12,13`)
    ]);
    const codeTables = responseCodeTable.data.body;
    setCommodities(codeTables.filter(codeTable => codeTable.code_table_type_id === 12))
    const sortedMarkets = codeTables.filter((codeTable) => codeTable.code_table_type_id === 13)?.sort((a, b) => a.sort_order - b.sort_order);
    setMarkets(sortedMarkets);
  }


  const onHandleSend = async (e) => {
    e.preventDefault();
    setLoading(true);
    setText("");
    setSourceDocuments([]);
    try {
      if (title) {
        const namespace = `${selectedCommodities[0]}_${selectedMarkets[0]}`;
        const [responseMessage] = await Promise.all([preditMessageWithReferences(title, namespace)]);
        if (responseMessage) {
          setText(responseMessage.summary.split("\n").map(function(item, idx) {
            return (
                <span key={idx}>
                    {item}
                    <br/>
                </span>
             )
          }));
          setSourceDocuments(removeDuplicatedSources(responseMessage.sourceDocuments));
        } else {
          setText(`Sorry, ieQip AI doesn't have this data`);
        }
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const removeDuplicatedSources = (messages) => {
    // sort messages by source (reference document)
    const sortedMessages = messages.sort((a, b) => {
      const sourceA = a.metadata.source.toLowerCase();
      const sourceB = b.metadata.source.toLowerCase();

      if (sourceA < sourceB) {
        return -1;
      }
      if (sourceA > sourceB) {
        return 1;
      }
      return 0;
    });

    // if current and the next one have the same source then set null to current source
    const result = sortedMessages.map((message, index) => {
      if (index < sortedMessages.length && message?.metadata?.source === sortedMessages[index + 1]?.metadata?.source) {
        message.metadata.source = null;
      }
      return message;
    });

    return result;
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleCommodityChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCommodities(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleMarketChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedMarkets(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  return (
    <div>
      <Dialog open={open} onClose={handleClose} scroll="paper" fullWidth aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">
        <Box>
              <FormControl sx={{ minWidth: 200, marginTop: 1, marginBottom: 1, marginRight: 1 }}>
                <InputLabel id="commodity-label">Commodity</InputLabel>
                {Array.isArray(commodities) && <Select multiple fullWidth labelId="commodity-label" label="Commodity" value={selectedCommodities || []} onChange={handleCommodityChange}
                  renderValue={(selected) => {
                    const result = Array.isArray(selected) ? selected.join(', ') : [...selected]
                    return result;
                  }}
                >
                  {commodities.map((option) => (
                    <MenuItem value={option.code}>
                      <Checkbox checked={selectedCommodities?.indexOf(option.code) > -1} />
                      {`${option.description}`}
                    </MenuItem>
                  ))}
                </Select>}
              </FormControl>
              <FormControl sx={{ minWidth: 200, marginTop: 1, marginBottom: 1 }}>
                <InputLabel id="market-label">Market</InputLabel>
                {Array.isArray(markets) && <Select multiple fullWidth labelId="market-label" label="Market" value={selectedMarkets || []} onChange={handleMarketChange}
                  renderValue={(selected) => {
                    const result = Array.isArray(selected) ? selected.join(', ') : [...selected]
                    return result;
                  }}
                >
                  {markets.map((option) => (
                    <MenuItem value={option.code}>
                      {MARKET_INDENTED_LIST.includes(option.code) && <div>&nbsp;&nbsp;&nbsp;</div>}
                      <Checkbox checked={selectedMarkets?.indexOf(option.code) > -1} />
                      {`${option.description}`}
                    </MenuItem>
                  ))}
                </Select>}
              </FormControl>
            </Box>
          <Paper component="form" elevation={3} sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}>
              <InputBase sx={{ ml: 1, flex: 1 }} color="info" placeholder="Send a message..." inputProps={{ "aria-label": "Send a message..." }} value={title} onChange={(e) => setTitle(e.target.value)} />
              <IconButton type="submit" color="primary" sx={{ p: "10px" }} aria-label="search" onClick={onHandleSend}>
                {loading ? <CircularProgress size={23} /> : <SendIcon color="primary" />}
              </IconButton>
          </Paper>
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
            {text && <AskMeAnswer title="Summary" message={text} />}
            {sourceDocuments?.length > 0 && <AskMeAnswer title={"References"} messages={sourceDocuments} />}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
